<template>
	<div class="md-layout">
		<div
			class="md-layout-item md-size-50 md-small-size-100"
			v-if="hasPermission('dashboard:dashboard_devices_on_platform')">
			<stats-card header-color="blue">
				<template slot="header">
					<div class="card-icon">
						<md-icon>router</md-icon>
					</div>
					<p class="category">{{ $t("dashboard.realtime.devices") }}</p>
					<h3 class="title">
						<animated-number :value="totalDevicesQt" :key="totalDevicesQt"></animated-number>
					</h3>
				</template>
				<template slot="footer">
					<div class="stats">
						<md-icon>update</md-icon>
						{{ $t("dashboard.realtime.updated") }}
					</div>
				</template>
			</stats-card>
		</div>
		<div
			class="md-layout-item md-size-50 md-small-size-100"
			v-if="hasPermission('dashboard:dashboard_online_devices_on_platform')">
			<stats-card header-color="green">
				<template slot="header">
					<div class="card-icon">
						<md-icon>router</md-icon>
					</div>
					<p class="category">{{ $t("dashboard.realtime.online") }}</p>
					<h3 class="title">
						<animated-number :value="onlineDevicesQt" :key="onlineDevicesQt"></animated-number>
					</h3>
				</template>
				<template slot="footer">
					<div class="stats">
						<md-icon>update</md-icon>
						<a href="#/devices">{{ $t("dashboard.realtime.viewDevices") }}</a>
					</div>
				</template>
			</stats-card>
		</div>
		<div
			class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
			v-if="hasPermission('dashboard:dashboard_monitor_devices')">
			<stats-card header-color="warning">
				<template slot="header">
					<div class="card-icon">
						<md-icon>monitor_heart</md-icon>
					</div>
					<p class="category">{{ $t("dashboard.realtime.monitored") }}</p>
					<h3 class="title">
						<animated-number :value="monitoredDevicesQt" :key="monitoredDevicesQt"></animated-number>
					</h3>
				</template>
				<template slot="footer">
					<div class="stats">
						<md-icon>update</md-icon>
						<a href="#/monitoring">{{ $t("dashboard.realtime.viewMonitoring") }}</a>
					</div>
				</template>
			</stats-card>
		</div>
		<div
			class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
			v-if="hasPermission('dashboard:dashboard_backup_devices')">
			<stats-card header-color="warning">
				<template slot="header">
					<div class="card-icon">
						<md-icon>cloud_done</md-icon>
					</div>
					<p class="category">{{ $t("dashboard.realtime.backuped") }}</p>
					<h3 class="title">
						<animated-number :value="backupedDevicesQt" :key="backupedDevicesQt"></animated-number>
					</h3>
				</template>
				<template slot="footer">
					<div class="stats">
						<md-icon>update</md-icon>
						<a href="#/backups">{{ $t("dashboard.realtime.viewBackups") }}</a>
					</div>
				</template>
			</stats-card>
		</div>

		<div
			class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
			v-if="hasPermission('dashboard:dashboard_sdwan_devices')">
			<stats-card header-color="primary">
				<template slot="header">
					<div class="card-icon">
						<md-icon>merge</md-icon>
					</div>
					<p class="category">{{ $t("dashboard.realtime.sdwan") }}</p>
					<h3 class="title">
						<animated-number :value="sdwanDevicesQt" :key="sdwanDevicesQt"></animated-number>
					</h3>
				</template>
				<template slot="footer">
					<div class="stats">
						<md-icon>update</md-icon>
						<a href="#/sdwan">{{ $t("dashboard.realtime.viewSdwan") }}</a>
					</div>
				</template>
			</stats-card>
		</div>
		<div
			class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
			v-if="hasPermission('dashboard:dashboard_contentfiltering_devices')">
			<stats-card header-color="primary">
				<template slot="header">
					<div class="card-icon">
						<md-icon>lock</md-icon>
					</div>
					<p class="category">{{ $t("dashboard.realtime.contentfiltering") }}</p>
					<h3 class="title">
						<animated-number
							:value="contentfilteringDevicesQt"
							:key="contentfilteringDevicesQt"></animated-number>
					</h3>
				</template>
				<template slot="footer">
					<div class="stats">
						<md-icon>update</md-icon>
						<a href="#/content-filtering">{{ $t("dashboard.realtime.viewContentFiltering") }}</a>
					</div>
				</template>
			</stats-card>
		</div>

		<div class="md-layout" v-if="hasPermission('dashboard:dashboard_trends')">
			<div class="md-layout-item md-size-50 md-small-size-100">
				<chart-card
					ref="totalWeekChart"
					header-animation="true"
					:chart-data="totalWeekChartData"
					:chart-options="chartOptions"
					chart-type="Line"
					chart-inside-header
					no-footer
					background-color="blue">
					<template slot="content">
						<h4 class="title">{{ $t("dashboard.history.devices") }}</h4>
						<p class="category">
							<code>({{ $t("dashboard.history.updateDate") }})</code>
						</p>
					</template>
					>
				</chart-card>
			</div>
			<div class="md-layout-item md-size-50 md-small-size-100">
				<chart-card
					ref="activeWeekChart"
					header-animation="true"
					:chart-data="activeWeekChartData"
					:chart-options="chartOptions"
					chart-type="Line"
					chart-inside-header
					no-footer
					background-color="green">
					<template slot="content">
						<h4 class="title">{{ $t("dashboard.history.onlineDevices") }}</h4>
						<p class="category">
							<code>({{ $t("dashboard.history.updateDate") }})</code>
						</p>
					</template>
				</chart-card>
			</div>
			<div class="md-layout-item md-size-50 md-small-size-100">
				<chart-card
					ref="totalChart"
					header-animation="true"
					:chart-data="totalChartData"
					:chart-options="chartOptions"
					chart-type="Bar"
					chart-inside-header
					no-footer
					background-color="blue">
					<template slot="content">
						<h4 class="title">{{ $t("dashboard.history.devicesAnnual") }}</h4>
						<p class="category"></p>
					</template>
				</chart-card>
			</div>
			<div class="md-layout-item md-size-50 md-small-size-100">
				<chart-card
					ref="activeChart"
					header-animation="true"
					:chart-data="activeChartData"
					:chart-options="chartOptions"
					chart-type="Bar"
					chart-inside-header
					no-footer
					background-color="green">
					<template slot="content">
						<h4 class="title">{{ $t("dashboard.history.onlineDevicesAnnual") }}</h4>
						<p class="category"></p>
					</template>
				</chart-card>
			</div>
		</div>
	</div>
</template>

<script>
import { StatsCard, ChartCard, AnimatedNumber } from "@/components";

import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import { mapGetters } from "vuex";

export default {
	data() {
		return {
			chartOptions: {},
			stats: {
				realTime: {},
				weekly: [],
				yearly: [],
			},
			alerts: [],
			totalDevicesQt: 0,
			onlineDevicesQt: 0,
			monitoredDevicesQt: 0,
			backupedDevicesQt: 0,
			sdwanDevicesQt: 0,
			contentfilteringDevicesQt: 0,
		};
	},
	mounted() {
		this.getStats();
	},
	methods: {
		async getStats() {
			let userData = TokenService.getUser();

			const [realTime, weekly, yearly] = await Promise.all([
				evoapi.get(`/customers/${userData.customerId}/stats/realtime`),
				evoapi.get(`/customers/${userData.customerId}/stats/week`),
				evoapi.get(`/customers/${userData.customerId}/stats/year`),
			]);

			this.stats.realTime = realTime.data;
			this.stats.weekly = weekly.data;
			this.stats.yearly = yearly.data;

			this.$nextTick(() => {
				this.$refs.activeWeekChart.initChart();
				this.$refs.totalWeekChart.initChart();
				this.$refs.activeChart.initChart();
				this.$refs.totalChart.initChart();
			});

			this.updateRealtimeData();
		},
		getRealtime(field) {
			return Number(this.stats?.realTime[field]) || 0;
		},
		updateRealtimeData() {
			// Aggiorna `totalDevicesQt` con il valore in tempo reale
			this.totalDevicesQt = this.getRealtime("total_devices_qt");
			this.onlineDevicesQt = this.getRealtime("active_devices_qt");
			this.monitoredDevicesQt = this.getRealtime("monitoring_devices_qt");
			this.backupedDevicesQt = this.getRealtime("backup_devices_qt");
			this.sdwanDevicesQt = this.getRealtime("sdwan_devices_qt");
			this.contentfilteringDevicesQt = this.getRealtime("content_filtering_qt");
		},
		parseDateDigit(num) {
			return num > 10 ? num : `0${num}`;
		},
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		realtimeOfflineDevices() {
			const total = this.stats?.realTime["total_devices_qt"];
			const online = this.stats?.realTime["active_devices_qt"];

			if (total && online) {
				return total - online;
			}

			return "-";
		},
		activeWeekChartData() {
			if (!this.stats.weekly) {
				return [];
			}

			const series = [this.stats.weekly.map((e) => e.active_devices_qt)];
			const labels = this.stats.weekly.map((e) => {
				const date = new Date(e.date);
				return `${this.parseDateDigit(date.getDate())}-${this.parseDateDigit(date.getMonth() + 1)}`;
			});

			return {
				labels: labels.reverse(),
				series: [series[0].reverse()],
			};
		},
		totalWeekChartData() {
			if (!this.stats.weekly) {
				return [];
			}

			const series = [this.stats.weekly.map((e) => e.total_devices_qt)];
			const labels = this.stats.weekly.map((e) => {
				const date = new Date(e.date);
				return `${this.parseDateDigit(date.getDate())}-${this.parseDateDigit(date.getMonth() + 1)}`;
			});

			return {
				labels: labels.reverse(),
				series: [series[0].reverse()],
			};
		},
		activeChartData() {
			if (!this.stats.weekly) {
				return [];
			}

			const series = [this.stats.yearly.map((e) => e.active_devices_qt)];
			const labels = this.stats.yearly.map((e) => {
				const date = new Date(e.date);
				return `${this.parseDateDigit(date.getMonth() + 1)}-${date.getFullYear()}`;
			});

			return {
				labels: labels.reverse(),
				series: [series[0].reverse()],
			};
		},
		totalChartData() {
			if (!this.stats.yearly) {
				return [];
			}

			const series = [this.stats.yearly.map((e) => e.total_devices_qt)];
			const labels = this.stats.yearly.map((e) => {
				const date = new Date(e.date);
				return `${this.parseDateDigit(date.getMonth() + 1)}-${date.getFullYear()}`;
			});

			return {
				labels: labels.reverse(),
				series: [series[0].reverse()],
			};
		},
	},
	components: {
		ChartCard,
		StatsCard,
		AnimatedNumber,
	},
};
</script>
