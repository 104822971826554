<template>
	<!-- Prima md-card: Marketplace -->
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>store</md-icon>
					</div>
					<h4 class="title">{{ $t("marketplace.title") }}</h4>
					<div class="view-toggle text-right">
						<md-button
							class="md-just-icon md-simple"
							:class="{ 'md-primary': viewMode === 'grid' }"
							@click="viewMode = 'grid'">
							<md-icon>grid_view</md-icon>
						</md-button>
						<md-button
							class="md-just-icon md-simple"
							:class="{ 'md-primary': viewMode === 'list' }"
							@click="viewMode = 'list'">
							<md-icon>view_list</md-icon>
						</md-button>
					</div>
				</md-card-header>

				<md-card-content>
					<div :class="['services-container']">
						<!-- Search and Filters -->
						<div class="filters-container">
							<md-field class="search-field">
								<md-icon>search</md-icon>
								<label>{{ $t("marketplace.search") }}</label>
								<md-input v-model="searchQuery"></md-input>
							</md-field>

							<div class="tag-filters" v-if="uniqueTags.length > 0">
								<button
									v-for="tag in uniqueTags"
									:key="tag"
									class="tag-button"
									:class="[`tag-${tag.toLowerCase()}`, { active: selectedTags.includes(tag) }]"
									@click="toggleTag(tag)">
									{{ tag }}
								</button>
							</div>
						</div>

						<!-- Servizi Attivi -->
						<template v-if="filteredActiveModules.length > 0">
							<div class="services-section">
								<h3 class="section-title">{{ $t("marketplace.active_services") }}</h3>
								<div :class="['services-grid', viewMode === 'grid' ? 'grid-view' : 'list-view']">
									<md-card
										v-for="module in filteredActiveModules"
										:key="module.id"
										:class="[
											'service-card',
											module.tags ? `tag-${module.tags[0].toLowerCase()}` : '',
										]"
										@click.native="handleModuleClick(module)">
										<md-card-header class="custom-header">
											<div class="card-title-container">
												<md-icon :class="{ 'active-icon': module.active }">{{
													module.icon
												}}</md-icon>
												<span class="module-title">{{
													$t(`marketplace.modules.${module.name_key}.name`)
												}}</span>
											</div>
										</md-card-header>
										<md-card-content>
											<div class="tags-container">
												<span
													v-for="tag in module.tags"
													:key="tag"
													:class="['module-tag', `tag-${tag.toLowerCase()}`]">
													{{ tag }}
												</span>
											</div>
											<p
												class="description-text"
												v-html="$t(`marketplace.modules.${module.name_key}.description`)"></p>
											<div v-if="!module.active" class="coming-soon">
												{{ $t("marketplace.coming_soon") }}
											</div>
										</md-card-content>
									</md-card>
								</div>
							</div>
						</template>

						<!-- Servizi Coming Soon -->
						<template v-if="filteredInactiveModules.length > 0">
							<div class="services-section">
								<h3 class="section-title">{{ $t("marketplace.coming_soon_services") }}</h3>
								<div :class="['services-grid', viewMode === 'grid' ? 'grid-view' : 'list-view']">
									<md-card
										v-for="module in filteredInactiveModules"
										:key="module.id"
										:class="[
											'service-card',
											'inactive',
											module.tags ? `tag-${module.tags[0].toLowerCase()}` : '',
										]"
										@click.native="handleModuleClick(module)">
										<md-card-header class="custom-header">
											<div class="card-title-container">
												<md-icon :class="{ 'active-icon': module.active }">{{
													module.icon
												}}</md-icon>
												<span class="module-title">{{
													$t(`marketplace.modules.${module.name_key}.name`)
												}}</span>
											</div>
										</md-card-header>
										<md-card-content>
											<div class="tags-container">
												<span
													v-for="tag in module.tags"
													:key="tag"
													:class="['module-tag', `tag-${tag.toLowerCase()}`]">
													{{ tag }}
												</span>
											</div>
											<p
												class="description-text"
												v-html="$t(`marketplace.modules.${module.name_key}.description`)"></p>
											<div v-if="!module.active" class="coming-soon">
												{{ $t("marketplace.coming_soon") }}
											</div>
										</md-card-content>
									</md-card>
								</div>
							</div>
						</template>
					</div>
				</md-card-content>
			</md-card>
		</div>
		<div class="md-layout-item" v-if="hasPermission('marketplace:marketplace_requests')">
			<md-card>
				<md-card-header class="md-card-header-icon">
					<div class="card-icon purple-icon">
						<md-icon>list_alt</md-icon>
					</div>
					<h4 class="title">{{ $t("marketplace.requests.title") }}</h4>
				</md-card-header>

				<md-card-content>
					<div v-if="!requests || requests.length === 0" class="text-center">
						<p>{{ $t("marketplace.requests.no_requests") }}</p>
					</div>
					<md-table
						v-else
						v-model="requests"
						:table-header-color="tableHeaderColor"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="ID" md-sort-by="id">{{ item.id }}</md-table-cell>
							<md-table-cell md-label="Module" md-sort-by="module_id">
								{{ getModuleName(item.module_id) }}
							</md-table-cell>
							<md-table-cell md-label="Option" md-sort-by="option">{{ item.option }}</md-table-cell>
							<md-table-cell md-label="Status" md-sort-by="status" class="status-cell">
								<md-chip :class="getStatusClass(item.status)" class="status-chip">
									{{ item.status }}
								</md-chip>
							</md-table-cell>
							<md-table-cell md-label="Description">{{ item.description }}</md-table-cell>
							<md-table-cell md-label="Created" md-sort-by="created_at">
								{{ new Date(item.created_at).toLocaleString() }}
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
			</md-card>
		</div>

		<!-- Request Modal -->
		<md-dialog :md-active.sync="showRequestModal" class="request-modal">
			<md-dialog-title>{{ $t(`marketplace.modules.${selectedModule?.name_key}.name`) }}</md-dialog-title>

			<md-dialog-content>
				<div
					class="module-description"
					v-if="selectedModule"
					v-html="$t(`marketplace.modules.${selectedModule.name_key}.description`)"></div>

				<!-- Device Selection -->
				<md-field v-if="selectedModule?.device_required">
					<label>{{ $t("marketplace.device") }}</label>
					<md-select v-model="requestForm.device_id">
						<md-option v-for="device in devices" :key="device.device_id" :value="device.device_id">
							{{ device.name }}
						</md-option>
					</md-select>
				</md-field>

				<!-- Options Selection -->
				<md-field>
					<label>{{ $t("marketplace.options") }}</label>
					<md-select v-model="requestForm.option" class="multiline-select">
						<md-option
							v-for="option in moduleOptions"
							:key="option"
							:value="option"
							class="multiline-option">
							{{ $t(`marketplace.modules.${selectedModule.name_key}.option_descriptions.${option}`) }}
						</md-option>
					</md-select>
				</md-field>

				<!-- Notes -->
				<md-field>
					<label>{{ $t("marketplace.description") }}</label>
					<md-textarea v-model="requestForm.notes" md-autogrow></md-textarea>
				</md-field>
			</md-dialog-content>

			<md-dialog-actions>
				<md-button class="md-success" @click="submitRequest" :disabled="!isFormValid">
					{{ $t("general.confirm") }}
				</md-button>
				<md-button class="md-danger" @click="closeRequestModal">
					{{ $t("general.cancel") }}
				</md-button>
			</md-dialog-actions>
		</md-dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import TokenService from "@/services/token";
import evoapi from "@/services/evoapi";

export default {
	name: "MarketplaceView",
	data() {
		return {
			viewMode: "grid",
			showRequestModal: false,
			selectedModule: null,
			marketplaceModules: [],
			availableDevices: [],
			searchQuery: "",
			allModules: [],
			selectedTags: [],
			selectedOption: null,
			selectedDevice: null,
			requestDescription: "",
			devices: [], // Rinominato da tableData a devices per chiarezza
			requestForm: {
				device_id: null,
				option: null,
				notes: "",
				customer_id: null,
				user_id: null,
			},
			pagination: {
				perPage: 25,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			requests: [],
			currentSort: "created_at",
			currentSortOrder: "desc",
			tableHeaderColor: "primary",
		};
	},
	computed: {
		...mapGetters("permissions", ["hasPermission"]),
		userData() {
			return TokenService.getUser();
		},
		activeModules() {
			return this.allModules.filter((module) => module.active);
		},
		inactiveModules() {
			return this.allModules.filter((module) => !module.active);
		},
		uniqueTags() {
			const tags = new Set();
			this.allModules.forEach((module) => {
				if (module.tags && module.visible !== false) {
					module.tags.forEach((tag) => tags.add(tag));
				}
			});
			return Array.from(tags);
		},
		filteredActiveModules() {
			return this.allModules.filter((module) => {
				const matchesSearch =
					!this.searchQuery ||
					this.$t(`marketplace.modules.${module.name_key}.name`)
						.toLowerCase()
						.includes(this.searchQuery.toLowerCase()) ||
					this.$t(`marketplace.modules.${module.name_key}.description`)
						.toLowerCase()
						.includes(this.searchQuery.toLowerCase());

				const matchesTags =
					this.selectedTags.length === 0 ||
					(module.tags && this.selectedTags.every((tag) => module.tags.includes(tag)));

				return module.active && matchesSearch && matchesTags;
			});
		},
		filteredInactiveModules() {
			return this.allModules.filter((module) => {
				const matchesSearch =
					!this.searchQuery ||
					this.$t(`marketplace.modules.${module.name_key}.name`)
						.toLowerCase()
						.includes(this.searchQuery.toLowerCase()) ||
					this.$t(`marketplace.modules.${module.name_key}.description`)
						.toLowerCase()
						.includes(this.searchQuery.toLowerCase());

				const matchesTags =
					this.selectedTags.length === 0 ||
					(module.tags && this.selectedTags.every((tag) => module.tags.includes(tag)));

				return !module.active && matchesSearch && matchesTags;
			});
		},
		isFormValid() {
			if (!this.selectedModule || !this.requestForm.option) return false;

			// Verifica device_id solo se il modulo lo richiede
			if (this.selectedModule.device_required && !this.requestForm.device_id) {
				return false;
			}

			return true;
		},
		moduleOptions() {
			if (!this.selectedModule?.name_key) return [];
			const options = this.$t(`marketplace.modules.${this.selectedModule.name_key}.options`);
			return Array.isArray(options) ? options : options.split("|");
		},
	},
	methods: {
		async fetchModules() {
			try {
				const response = await evoapi.get(`/customers/${this.userData.customerId}/marketplace/modules`);
				this.allModules = response.data;
			} catch (error) {
				this.showNotification(this.$t("marketplace.load_error"), "danger");
			}
		},

		async fetchDevices() {
			try {
				const userData = TokenService.getUser();
				const response = await evoapi.get("/customers/" + userData.customerId + "/devices");
				this.devices = response.data;
			} catch (error) {
				//console.error("Error fetching devices:", error);
				this.$toast.error(this.$t("marketplace.error_fetching_devices"));
			}
		},

		getModuleOptions(module) {
			const optionsKey = `marketplace.modules.${module.name_key}.options`;
			const optionsString = this.$t(optionsKey);
			return optionsString.split("|");
		},

		handleModuleClick(module) {
			if (!module.active || module.route === "form") {
				// Se il modulo non è attivo O la route è 'form', apri il modale
				this.selectedModule = module;
				this.resetForm();
				this.fetchDevices();
				this.showRequestModal = true;
			} else {
				// Altrimenti, naviga alla route del modulo
				this.$router.push(module.route);
			}
		},

		resetForm() {
			this.requestForm = {
				device_id: null,
				option: null,
				notes: "",
			};
		},

		closeRequestModal() {
			this.showRequestModal = false;
			this.resetForm();
		},

		async submitRequest() {
			if (!this.isFormValid) return;

			try {
				const userData = TokenService.getUser();
				const requestData = {
					module_id: this.selectedModule.id,
					option: this.requestForm.option,
					description: this.requestForm.notes,
					customer_id: parseInt(userData.customerId),
					user_id: parseInt(userData.userId),
				};

				// Aggiungi device_id solo se richiesto
				if (this.selectedModule.device_required) {
					requestData.device_id = parseInt(this.requestForm.device_id);
				}

				await evoapi.post("/customers/" + userData.customerId + "/marketplace/request", requestData);

				this.showNotification(this.$t("marketplace.request_success"), "success");
				this.closeRequestModal();
				this.fetchRequests();
			} catch (error) {
				//console.error("Error submitting request:", error);
				this.showNotification(this.$t("marketplace.request_error"), "danger");
			}
		},

		showNotification(message, type = "success") {
			this.$notify({
				message,
				icon: type === "success" ? "check" : "error",
				horizontalAlign: "right",
				verticalAlign: "top",
				type,
			});
		},

		toggleTag(tag) {
			const index = this.selectedTags.indexOf(tag);
			if (index === -1) {
				this.selectedTags.push(tag);
			} else {
				this.selectedTags.splice(index, 1);
			}
		},

		async fetchRequests() {
			try {
				const userData = TokenService.getUser();
				const response = await evoapi.get(`/customers/${userData.customerId}/marketplace/requests`);
				this.requests = response.data;
			} catch (error) {
				//console.error("Error fetching requests:", error);
				this.showNotification(this.$t("marketplace.requests.load_error"), "danger");
			}
		},

		getModuleName(moduleId) {
			const module = this.allModules.find((m) => m.id === moduleId);
			return module ? this.$t(`marketplace.modules.${module.name_key}.name`) : moduleId;
		},

		getStatusClass(status) {
			const statusClasses = {
				pending: "md-pending",
				done: "md-done",
				"in progress": "md-in-progress",
			};
			return statusClasses[status.toLowerCase()] || "md-default";
		},
	},
	async created() {
		try {
			await Promise.all([this.fetchModules(), this.fetchRequests()]);
		} catch (error) {
			//console.error("Error in created hook:", error);
		}
	},
};
</script>

<style lang="scss" scoped>
.services-container {
	padding: 20px;
}

.services-section {
	margin-bottom: 40px;

	.section-title {
		font-size: 18px;
		color: #333;
		margin-bottom: 20px;
		padding: 0;
	}
}

.services-grid {
	&.grid-view {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		gap: 20px;
	}

	&.list-view {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}

.service-card {
	position: relative;
	padding-left: 0 !important;
	border-left: none !important;

	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 4px;
	}

	&.tag-bonding::before {
		left: 0;
		width: 4px;
		background: #0288d1;
	}

	&.tag-cybersecurity::before {
		left: 0;
		width: 4px;
		background: #7b1fa2;
	}

	&.tag-failover::before {
		left: 0;
		width: 4px;
		background: #388e3c;
	}

	&.tag-help::before {
		left: 0;
		width: 4px;
		background: #f57c00;
	}

	&.tag-network::before {
		left: 0;
		width: 4px;
		background: #1976d2;
	}

	&.tag-training::before {
		left: 0;
		width: 4px;
		background: #689f38;
	}

	&.tag-vpn::before {
		left: 0;
		width: 4px;
		background: #512da8;
	}

	&.tag-log::before {
		left: 0;
		width: 4px;
		background: #3f51b5;
	}

	&.tag-ai::before {
		left: 0;
		width: 4px;
		background: #e91e63;
	}

	&.tag-iot::before {
		left: 0;
		width: 4px;
		background: #009688;
	}

	cursor: pointer;
	transition: transform 0.2s, box-shadow 0.2s;
	position: relative;

	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	}

	&.inactive {
		cursor: default;
		opacity: 0.7;

		&:hover {
			transform: none;
			box-shadow: none;
		}

		.md-icon {
			filter: grayscale(100%);
		}
	}

	.custom-header {
		padding: 16px;
		display: block !important;
		text-align: left !important;

		::v-deep .md-card-header-content {
			display: block !important;
			padding: 0 !important;
		}
	}

	.card-title-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		width: 100%;

		.md-icon {
			margin: 0 !important;
			font-size: 24px !important;
		}

		.module-title {
			font-size: 20px;
			font-weight: 600;
			color: #333;
		}
	}

	.active-icon {
		color: #4caf50; // Verde acceso, puoi cambiare il colore come preferisci
	}

	.card-footer {
		margin-top: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.device-info {
		display: flex;
		align-items: center;
		gap: 4px;
		color: rgba(0, 0, 0, 0.6);
	}

	.coming-soon {
		background: #f5f5f5;
		color: #666;
		padding: 4px 8px;
		border-radius: 4px;
		font-size: 0.85em;
		font-weight: 500;
		text-transform: uppercase;
	}

	.description-text {
		font-size: 12px;
		color: #666;
		margin-top: 8px;
		line-height: 1.4;
		transition: all 0.3s ease;

		p {
			margin: 0;
			font-size: inherit;
		}
	}

	.tags-container {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin-top: 8px;

		.module-tag {
			font-size: 12px;
			padding: 4px 8px;
			border-radius: 12px;

			// Colori per i tag nelle card
			&.tag-bonding {
				background: #e1f5fe;
				color: #0288d1;
			}

			&.tag-cybersecurity {
				background: #f3e5f5;
				color: #7b1fa2;
			}

			&.tag-failover {
				background: #e8f5e9;
				color: #388e3c;
			}

			&.tag-help {
				background: #fff3e0;
				color: #f57c00;
			}

			&.tag-network {
				background: #e3f2fd;
				color: #1976d2;
			}

			&.tag-training {
				background: #f1f8e9;
				color: #689f38;
			}

			&.tag-vpn {
				background: #ede7f6;
				color: #512da8;
			}

			// Default style
			&:not([class*="tag-"]) {
				background: #f5f5f5;
				color: rgba(0, 0, 0, 0.6);
			}

			// Nuovi tag nelle card
			&.tag-log {
				background: #e8eaf6;
				color: #3f51b5;
			}

			&.tag-ai {
				background: #fce4ec;
				color: #e91e63;
			}

			&.tag-iot {
				background: #e0f2f1;
				color: #009688;
			}
		}
	}

	.coming-soon {
		background: #f5f5f5;
		color: #666;
		padding: 4px 8px;
		border-radius: 4px;
		font-size: 0.85em;
		font-weight: 500;
		text-transform: uppercase;
		display: inline-block;
		margin-top: 8px;
	}
}

.view-toggle {
	position: absolute;
	top: 16px;
	right: 16px;
}

.search-container {
	padding: 0 20px 20px;
	max-width: 300px;

	.md-field {
		margin: 0;
	}
}

.filters-container {
	padding: 16px;

	.search-field {
		max-width: 300px;
	}

	.tag-filters {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin-top: 16px;

		.tag-button {
			border: none;
			border-radius: 16px;
			padding: 6px 12px;
			font-size: 13px;
			cursor: pointer;
			transition: all 0.2s ease;
			outline: none;

			// Colori predefiniti per ogni tag
			&.tag-bonding {
				background: #e1f5fe;
				color: #0288d1;
				&:hover {
					background: #b3e5fc;
				}
				&.active {
					background: #0288d1;
					color: white;
				}
			}

			&.tag-cybersecurity {
				background: #f3e5f5;
				color: #7b1fa2;
				&:hover {
					background: #e1bee7;
				}
				&.active {
					background: #7b1fa2;
					color: white;
				}
			}

			&.tag-failover {
				background: #e8f5e9;
				color: #388e3c;
				&:hover {
					background: #c8e6c9;
				}
				&.active {
					background: #388e3c;
					color: white;
				}
			}

			&.tag-help {
				background: #fff3e0;
				color: #f57c00;
				&:hover {
					background: #ffe0b2;
				}
				&.active {
					background: #f57c00;
					color: white;
				}
			}

			&.tag-network {
				background: #e3f2fd;
				color: #1976d2;
				&:hover {
					background: #bbdefb;
				}
				&.active {
					background: #1976d2;
					color: white;
				}
			}

			&.tag-training {
				background: #f1f8e9;
				color: #689f38;
				&:hover {
					background: #dcedc8;
				}
				&.active {
					background: #689f38;
					color: white;
				}
			}

			&.tag-vpn {
				background: #ede7f6;
				color: #512da8;
				&:hover {
					background: #d1c4e9;
				}
				&.active {
					background: #512da8;
					color: white;
				}
			}

			// Stile di default per eventuali nuovi tag
			&:not([class*="tag-"]) {
				background: #f5f5f5;
				color: rgba(0, 0, 0, 0.6);
				&:hover {
					background: #e0e0e0;
				}
				&.active {
					background: var(--md-theme-default-primary);
					color: white;
				}
			}

			// Nuovi tag
			&.tag-log {
				background: #e8eaf6;
				color: #3f51b5;
				&:hover {
					background: #c5cae9;
				}
				&.active {
					background: #3f51b5;
					color: white;
				}
			}

			&.tag-ai {
				background: #fce4ec;
				color: #e91e63;
				&:hover {
					background: #f8bbd0;
				}
				&.active {
					background: #e91e63;
					color: white;
				}
			}

			&.tag-iot {
				background: #e0f2f1;
				color: #009688;
				&:hover {
					background: #b2dfdb;
				}
				&.active {
					background: #009688;
					color: white;
				}
			}
		}
	}
}

.service-card {
	transition: all 0.2s ease;
	border-left: 4px solid transparent;

	// Colori per le card basati sui tag
	&.tag-bonding {
		border-left-color: #0288d1;
		&:hover {
			background: #e1f5fe;
		}
	}

	&.tag-cybersecurity {
		border-left-color: #7b1fa2;
		&:hover {
			background: #f3e5f5;
		}
	}

	&.tag-failover {
		border-left-color: #388e3c;
		&:hover {
			background: #e8f5e9;
		}
	}

	&.tag-help {
		border-left-color: #f57c00;
		&:hover {
			background: #fff3e0;
		}
	}

	&.tag-network {
		border-left-color: #1976d2;
		&:hover {
			background: #e3f2fd;
		}
	}

	&.tag-training {
		border-left-color: #689f38;
		&:hover {
			background: #f1f8e9;
		}
	}

	&.tag-vpn {
		border-left-color: #512da8;
		&:hover {
			background: #ede7f6;
		}
	}

	// Default style for cards without tags
	&:not([class*="tag-"]) {
		border-left-color: var(--md-theme-default-primary);
		&:hover {
			background: #f5f5f5;
		}
	}

	// Style for inactive cards
	&.inactive {
		opacity: 0.7;
		&:hover {
			opacity: 0.9;
		}
	}
}

.request-modal {
	::v-deep .md-dialog-container {
		position: fixed !important;
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.md-dialog-title {
		padding: 40px 24px 0;
		margin-bottom: 30px;
		font-size: 30px;
		font-weight: 500;
	}

	.md-dialog-content {
		padding: 0 24px 24px;
		max-height: calc(90vh - 180px);
		overflow-y: auto;

		.md-field {
			margin-bottom: 24px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.module-description {
			margin-bottom: 24px;
			line-height: 1.5;
			color: rgba(0, 0, 0, 0.87);
		}
	}

	.md-dialog-actions {
		padding: 12px 24px;
		justify-content: flex-end;

		.md-button {
			margin-left: 8px;
		}
	}

	.md-field {
		&.md-has-value {
			margin-top: 16px;
		}

		.md-textarea {
			min-height: 100px;
		}
	}
}

.md-card-header-primary {
	background: linear-gradient(60deg, #ab47bc, #8e24aa);
}

.requests-section {
	margin-top: 50px;
}

.md-card-header {
	background: white !important;

	.title {
		color: #333;
	}
}

.status-cell {
	text-align: center !important;

	.status-chip {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: 80px;
		margin: 0 auto;
	}
}

.md-chip {
	&.md-pending {
		background-color: #9e9e9e !important; // Grigio
		color: white;
	}

	&.md-done {
		background-color: #4caf50 !important; // Verde
		color: white;
	}

	&.md-in-progress {
		background-color: #ffa726 !important; // Giallo
		color: white;
	}
}

.purple-icon {
	background: linear-gradient(60deg, #ab47bc, #8e24aa);
	color: white;
	padding: 15px;
	border-radius: 3px;
	margin-right: 15px;
}

.md-card {
	padding-left: 0;

	.md-card-content {
		padding-left: 16px;
	}
}

.md-menu-content {
	max-width: 90vw !important;
	width: auto !important;
	min-width: min(400px, 90vw) !important;

	.md-list {
		width: 100%;
		padding: 0;
	}

	.md-menu-content-container {
		max-height: 80vh !important;
	}
}

.md-list-item.md-menu-item {
	.md-list-item-container {
		height: auto !important;
		min-height: 48px !important;
		line-height: 1.4 !important;
		white-space: normal !important;
		padding: 12px 16px !important;
		justify-content: flex-start !important;
	}

	.md-list-item-content {
		height: auto !important;
		min-height: unset !important;
		white-space: normal !important;
		line-height: 1.4 !important;
	}
}
</style>
