var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[{ 'nav-open': _vm.$sidebar.showSidebar }, { rtl: _vm.$route.meta.rtlActive }]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[(_vm.loggedIn)?_c('template',{slot:"links"},[(_vm.hasPermission('dashboard:dashboard_list'))?_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.administration'),
					icon: 'admin_panel_settings',
					disableCollapse: true,
				}}},[(_vm.hasPermission('admin:tenant_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.tenants'),
						icon: 'business',
						path: '/tenants',
					}}}):_vm._e(),(_vm.hasPermission('admin:customer_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.customers'),
						icon: 'perm_contact_calendar',
						path: '/customers',
					}}}):_vm._e(),(_vm.hasPermission('admin:user_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.users'),
						icon: 'manage_accounts',
						path: '/users',
					}}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.devices_menu'),
					icon: 'menu_open',
					disableCollapse: true,
				}}},[(_vm.hasPermission('device:device_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.devices'),
						icon: 'router',
						path: '/devices',
					}}}):_vm._e(),(_vm.hasPermission('device:device_group_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.device_groups'),
						icon: 'router',
						path: '/device-groups',
					}}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.provision_menu'),
					icon: 'menu_open',
					disableCollapse: true,
				}}},[(_vm.hasPermission('provision:template_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.templates'),
						icon: 'text_snippet',
						path: '/templates',
					}}}):_vm._e(),(_vm.hasPermission('provision:provision_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.provisioners'),
						icon: 'location_searching',
						path: '/provisioners',
					}}}):_vm._e()],1),(_vm.hasPermission('monitoring:monitoring_list'))?_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.monitoring'),
					icon: 'monitor_heart',
					path: '/monitoring',
					isRoute: false,
				}}}):_vm._e(),(_vm.hasPermission('backup:backup_list'))?_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.backups'),
					icon: 'cloud_done',
					path: '/backups',
				}}}):_vm._e(),(_vm.hasPermission('marketplace:marketplace_list'))?_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.marketplace'),
					icon: 'store',
					path: '/marketplace',
				}}}):_vm._e(),(_vm.hasPermission('usage:usage_list'))?_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.usage'),
					icon: 'leaderboard',
					path: '/stats',
				}}},[(_vm.hasPermission('usage:stats_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.stats'),
						icon: 'analytics',
						path: '/stats',
					}}}):_vm._e(),(_vm.hasPermission('usage:licenses_list'))?_c('sidebar-item',{attrs:{"link":{
						name: _vm.$t('sidebar.licenses'),
						icon: 'workspace_premium',
						path: '/licenses',
					}}}):_vm._e()],1):_vm._e(),(_vm.hasPermission('profile:profile_list'))?_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.profile'),
					icon: 'person',
					path: '/profile',
				}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
					name: _vm.$t('sidebar.logout'),
					icon: 'logout',
					path: '/logout',
				}}})],1):_vm._e()],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }